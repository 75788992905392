import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent
} from '@angular/common/http';

import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage/storage.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private storageService: StorageService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // return next.handle(request).pipe(
        //     map((event: HttpEvent<any>) => {
        //         return event;
        //     })
        // );
        return from(this.storageService.getToken())
            .pipe(
                switchMap((token: any) => {
                    let requestClone;
                    if(request.url.includes('qc-api')){
                        requestClone = request.clone({
                            headers: request.headers
                                .append('Content-Type', 'application/json')
                                .append('x-jwt-token', token.value),
                            withCredentials: true
                        });
                    }
                    else{
                        requestClone = request.clone({
                            headers: request.headers
                                .append('Content-Type', 'application/json'),
                            params: request.params
                                .append('observe', 'response')
                        });
                    }
                   
                    return next.handle(requestClone).pipe(
                        map((event: HttpEvent<any>) => {
                            return event;
                        })
                    );
                })
            );
    }
}