import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../login/login.service';

import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanLoad {

  constructor(
    private router: Router,
    private storageService: StorageService,
    private authService: LoginService
  ) {
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isLoggedOut().then((isLoggedOut: any) => {
      console.log(isLoggedOut);
      
      if(isLoggedOut){
        this.router.navigateByUrl('/login');
      }
      
      return !isLoggedOut;
    });
  }

}
