import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, tap, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public onLogin = new EventEmitter();
  public onLogout = new EventEmitter();

  constructor(private http: HttpClient, private storageService: StorageService, private router: Router) { }

  validateUser(base64: any) {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', base64);

    const url = `${environment.authQC}/pos/login`
    return this.http.post(url, null, {headers});
  }

  login(username:string, password:string): Observable<any> {
    const body = {
      username: username,
      password: password!,
      account: environment.client
    };

    const url = `${environment.ssoUrl}/sso/v1/auth/login`;
    return this.http.post<any>(url, body, {observe: 'response' as 'body'})
      .pipe(
        tap((res:any) => this.setSession(res))
      )
      .pipe(
        shareReplay()
      )
      .pipe(map(user => {
        
        return user;
    }));
  }

  logout(): void {
    this.storageService.removeToken().then(() => {
      this.onLogout.emit();
    });
  }

  async isLoggedIn() {
    const expToken =  await this.storageService.getTokenExpiration();

    const currentDate = +Date.parse(new Date().toString());
    const expirationDate = expToken.value * 1000;
    return currentDate < expirationDate;
  }

  async isLoggedOut() {
      return await this.isLoggedIn() === false;
  }

  async getExpiration() {
    const expiration = await this.storageService.getTokenExpiration();
    const expiresAt = expiration;
    return expiresAt;
  }   

  private setSession(authResult:HttpResponse<any>): void {
    const token = authResult.headers.get('x-jwt-token')!;
    
    this.storageService.setToken(token).then(() => {
      this.onLogin.emit();
    });
  }
}
