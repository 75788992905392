import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { StorageService } from '../storage/storage.service';


@Injectable({
  providedIn: 'root'
})
export class InitUpdatedService {

  initStoreChanged = new Subject<{code: string, name: string, address: string}>();
  initStore: {code: string, name: string, address: string};
  

  constructor(
    private storageService: StorageService
  ) {
    this.showInitStore();
  }

  changeInitStore(storeCode: string, storeName: string, storeAddress: string) {
    this.initStore = {code: storeCode, name: storeName,address: storeAddress};
    this.initStoreChanged.next(this.initStore);
    this.storageService.setStoreInit(storeCode, storeName, storeAddress);
  }

  showInitStore() {
    this.storageService.getStore().then((res: any) => {
      console.log(JSON.parse(res.value));
      this.initStore = JSON.parse(res.value);
      this.initStoreChanged.next(this.initStore);
    });
  }

  getInitStore() {
    return this.initStore;
  }
}
