import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { InitUpdatedService } from 'src/app/services/init-updated/init-updated.service';
import { MemberExistService } from 'src/app/services/member/member-exist/member-exist.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  @Input() token: boolean;
  @Output() navigationClicked = new EventEmitter<boolean>();
  environment = environment;
  store: any;
  subscriptionInitStore: Subscription;

  constructor(
    private storageService: StorageService,
    public router: Router,
    private memberExistService: MemberExistService,
    private initUpdatedService: InitUpdatedService
  ) { }

  ngOnInit() {
    this.subscriptionInitStore = this.initUpdatedService.initStoreChanged.subscribe((store: {code: string, name: string, address: string}) => {
      this.store = store;
    });
    this.store = this.initUpdatedService.getInitStore(); 
  }

  onLogout() {
    this.storageService.removeToken().then(() => {
      this.memberExistService.checkMemberExists();
      this.router.navigateByUrl('/login');
    });
  }

  navigate(){
    this.navigationClicked.emit(true);
  }

}
